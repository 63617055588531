
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  mounted() {
    if (!this.getGDPR() === true) {
      this.isOpen = true;
    }
  },
  methods: {
    getGDPR() {
      if (process.browser) {
        return localStorage.getItem('GDPR:accepted');
      }
    },
    accept() {
      if (process.browser) {
        this.isOpen = false;
        localStorage.setItem('GDPR:accepted', 'yes');
        this.$ga.enable();
        this.$ga.page(this.$route.fullPath);
      }
    },
    deny() {
      if (process.browser) {
        this.isOpen = false;
        localStorage.setItem('GDPR:accepted', 'no');
        this.$ga.disable();
      }
    },
  },
};
